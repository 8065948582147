<template>
  <v-container>
    <v-row >
      <v-col cols="12" >
        <v-card>
          <v-card-text>  
            <v-col cols="12" class="ma-1 pa-1"  >
            <v-card class="elevation-0 transparent" align="left">
            <v-row>
              <!-- LOGO -->
              <v-col cols="3"  md="3" sm="3" xs="12"  class="ma-0 pa-0">
                <v-img max-height= "200" alt="Vue logo" src="@/assets/logo-embarques.png" contain></v-img>
              </v-col>

              <v-col cols="5" md="5" sm="5" xs="12">
                <v-card-text  align="center">
                  <h2 class="red--text">{{tipo}} {{Docum.numdoc}}</h2>
                  <h3 class="font-weight-black"> Fecha: {{ Docum.fecha}}</h3>
                </v-card-text>
              </v-col>
              <v-col cols="4" md="4" sm="4" xs="12">
              <v-card-actions >         	
              <!-- <v-btn small color="success" @click="order()" >Pagar</v-btn> -->
              <!-- <v-btn color="amber darken-3" dark >Editar</v-btn> -->

              <v-btn small color="secondary" 
                value="Print this page" >
                <v-icon color="white" 
                @click="mostrarComprobante">mdi-printer</v-icon>
              </v-btn>

              <v-btn small color="info" 
                :to="{name:'condocs'}">
                <v-icon color="white">mdi-reply</v-icon>
              </v-btn>

                </v-card-actions>
              </v-col>
            </v-row>      
          </v-card>
        </v-col>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>


<v-row>	
  <!-- DATOS DE LA EMPRESA -->
  <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="12">
    <v-card >
      <v-card-text>
        <div class="text-xs-left blue--text font-weight-black">{{ getCIA.nomcia }}</div>
        <div class="text-xs-left red--text "><strong>{{ getCIA.rfccia }}</strong></div>
        <div class="text-xs-left ">{{ getCIA.calle + ' ' + getCIA.numext + ' ' + getCIA.colonia  }}</div>
        <div class="text-xs-left ">{{  getCIA.ciudad + ', ' + getCIA.estado + ' CP: ' + getCIA.cp}}</div>  	      
      </v-card-text>
    </v-card>
  </v-col>

  <!-- DATOS DEL DOCUMENTO Y DEL CLIENTE-->
  <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="12">
    <v-card >
    <v-card-text>
    <div class="text-xs-left font-weight-black">{{ Clientes.nomcli }}</div>
    <div class="text-xs-left red--text font-weight-black "> RFC {{ Clientes.rfc }}</div>
    <div class="text-xs-left ">{{ Clientes.calle + ' ' + Clientes.numext + ' ' + Clientes.colonia }} </div>
    <div>{{Clientes.ciudad + ' ' + Clientes.estado }}  CP {{Clientes.cp}}</div>
    </v-card-text>
    </v-card>
  </v-col>
</v-row>

  <v-row>

<!--     <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="12" class="ma-0 pa-0">
      <v-card class="elevation-0 ma-0 pa-0">
        <v-card-text class="ma-0 pa-0">
          <v-radio-group v-model="radios" :mandatory="false" row dense readonly>
          <v-radio label="Recoger en tienda" value="1" color="primary"></v-radio>
          <v-radio label="Envío a domicilio" value="2" color="primary"></v-radio>
          </v-radio-group>
          <v-select 
            v-if ="radios==2"
            v-model="select"
            :hint="`  ${select.Calle} ${select.Numext} ${select.colonia} ${select.Ciudad}
            ${select.Estado} CP ${select.Cp} Tel. ${select.telefono} `"
            :items="getDirecciones"
            item-text="nombre"
            item-value="idweb" 
            label="Selecionar Dirección de envio"
            prepend-icon="house"
            persistent-hint
            return-object
            readonly
          ></v-select>
        </v-card-text>
      </v-card>
    </v-col>
 -->

<!-- //RENGLONES -->
    <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="12">
      <v-card  class="ma-1 pa-1">
        <v-card-text>
          <div> RPD: {{ Docum.rdp }} </div>
          <div> Transportista: {{ Docum.direnvio }} </div>
          <div> Sello: {{ Docum.sello }} </div>
          <div> Vehiculo: {{ Docum.vehiculo }} </div>
          <div> Chofer: {{ Docum.chofer }} </div>
          <div> Personal Kam: {{ Docum.kam}} </div>
          <div> Personal Cliente: {{ Docum.cat }} </div>
          <div> Observaciones: {{ Docum.obs }} </div>
      </v-card-text>
    </v-card>
  </v-col>



    <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="12"  class="ma-0 pa-0">
      <v-card  class="ma-1 pa-1">
        <v-row >
          <v-col xs6  class="ma-0 pa-0">
            <v-card class="transparent elevation-0 ma-0 py-0" width="100%">
              <v-card-text class="ma-1 px-1">
                <div align="right"><h3><strong>Importe:</strong></h3></div>
                <div align="right"><h3><strong>Descuento:</strong></h3></div>
                <div align="right"><h3><strong>Subtotal:</strong></h3></div>
                <div align="right"><h3><strong>Iva:</strong></h3></div>
                <div align="right"><h3><strong>Total:</strong></h3></div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col xs6 class="ma-1 pa-1">
            <v-card class="transparent elevation-0"  width="100%">
              <v-card-text align="right" class="mx-1 px-4">
              <div><h3><strong>{{ Importe }}</strong></h3></div>
              <div><h3><strong>{{ Descuento }}</strong></h3></div>
              <div><h3><strong>{{ Subtotal }}</strong></h3></div>
              <div><h3><strong>{{ Iva }}</strong></h3></div>
              <div><h3><strong>{{ Total }}</strong></h3></div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>

  <v-row>
    <!-- TABLA DE PARTIDAS  -->
    <v-col cols="12" xs="12" class="pa-2 ma-2">
        <v-data-table
          :headers="headers"
          :items="MovinArray"
          :items-per-page="longitud"
          fixed-header
          height="500"
          class="elevation-10 pa-1 text-xs-center"
          >
          


          <template v-slot:item="{ item }" >
          <tr v-on:dblclick="edit(item)">

          <td>{{ item.numpar}}</td>
          <td>{{ item.numart}}</td>
          <td>{{ item.descrip}}</td>
          <td align= 'end'>{{ item.cant}}</td>
          <td>{{ item.unidad }}</td>

          
          <td class="red--text" align= 'end'>{{ item.lote}}</td>

          <td class="blue--text">{{ item.numubi}}</td>

          <td align= 'end'>{{ item.tok}}</td>
          <td align= 'end'>{{ item.tu}}</td>
          <td align= 'end'>{{ item.bultos}}</td>
          <td align= 'end'>{{ item.emmpro}}</td>
          </tr>
          </template>

        </v-data-table>
    </v-col>
  </v-row>

  <v-row>
    <v-container 
      class="grey lighten-3"
      fluid >
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.nompago"
          cols="12"
          sm="6"
          md="6"
        >
          <v-card  :loading="loading"
            class="mx-auto my-4"
          >
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>

            <v-img
              :src= "card.src"
              height="200px"
              contain
            >
            </v-img>

            <v-card-actions class="white justify-center">
              <v-card-title>{{card.nombre}}</v-card-title>
            </v-card-actions>
          
        </v-card>
      </v-col>
    </v-row>  
  </v-container>  
 

    <!-- <v-col cols="12">
      <v-card     
        :loading="loading"
        class="mx-auto my-12"
        max-width="374"
      >
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-img contain
          height="250"
          :src="getFotoSello"
        ></v-img>

        <v-card-title>Foto Sello</v-card-title>
      </v-card>
    </v-col> -->
  </v-row>

  <!--<v-dialog v-model="comprobanteModal" max-width="1000">
    <Impresion/>
  </v-dialog>-->
  </v-container>	
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  // import jsPDF from 'jspdf'
  // import html2canvas from 'html2canvas';
  //  import autoTable from 'jspdf-autotable'
  // import Impresion from '@/views/pedidos/Impresion.vue'

	import tempMovin from '@/mixins/tempMovin.js';
	var accounting = require("accounting");
  var base64 = require('base-64');

  export default{
    mixins:[tempMovin],
    // components:{
    // Impresion
    // },
    data(){
      return{
        select: {nombre:'', idweb:'', encargado:'',
          Calle:'', Numext:'', colonia:'', Ciudad:'',
          Estado:'', Cp:'', telefono:'', numclisuc:'' 
        },
        logo:'',
        Importe:0,
        Descuento:0,
        Subtotal:0,
        Iva:0,
        Total:0,
        MovinArray:[],
        tipodoc: '',
        loading:true,
        Clientes:{},
        Movim: [],
        Docum: '',
        radios: '1',
        dialog: false,
        total:'',
        direccion:'',
        headers: [
        { text: 'Par'		   		,align: 'center', value: 'numpar'   ,sortable: false },
        { text: 'Clave'			  ,align: 'left', value: 'numart'	    ,sortable: false },
        { text: 'Descripción' ,align: 'left',  value: 'descrip'   ,sortable: false },
        { text: 'Cantidad'	   ,align: 'center', value: 'cant'		,sortable: false },
        { text: 'Unidad'	     ,align: 'center', value: 'unidad'	,sortable: false },
        { text: 'Lote'         ,align: 'end',   value: 'lote'    ,sortable: false },
        
        // { text: 'Precio'		   ,align: 'right',  value: 'precio'      ,sortable: false },
        // { text: 'Descuento'	   ,align: 'right',  value: 'descuento'     ,sortable: false },
        // // { text: 'Impuesto'	   ,align: 'right',  value: 'Impuesto1'   ,sortable: false },
        // { text: 'Importe'		   ,align: 'right',  value: 'importe'     ,sortable: false },
        // { text: 'Subtotal'		 ,align: 'right',  value: 'subtotal'    ,sortable: false },
        // { text: 'Precio Neto'  ,align: 'right',  value: 'total'       ,sortable: false },
        { text: 'Ubicación'  ,align: 'left',  value: 'numubi'       ,sortable: false },
        { text: 'TO'  ,align: 'right',  value: 'tok'       ,sortable: false },
        { text: 'TU'  ,align: 'right',  value: 'tu'       ,sortable: false },
        { text: 'Bultos'  ,align: 'right',  value: 'bulto'       ,sortable: false },
        { text: 'Empaque'  ,align: 'right',  value: 'emmpro'       ,sortable: false },        
        ],
        comprobanteModal:0,
        tipo:'',
        longitud:5,
        getCIA:{},
        //FOTOS
        cards:[],
        getFotoSello:require ("@/assets/logo-embarques.png" )
        // https://kam.saiterp.com/imagen/entradas/E69fotoSello.png
      }
    },

    created () {
      this.logo = "@/assets/logo-embarques.png"  
      let tipodoc = ''
      let numdoc  = ''  
      // let tiponum = {}
      console.log("router", this.$route.params) 
      console.log("getCIA", this.getCIA)

      if(this.$route.params != undefined){
        // si no manda parametros rechaazar 
        tipodoc = this.$route.params.tipodoc
        numdoc = this.$route.params.numdoc
        console.log(tipodoc, numdoc)
        let tiponum = { "tipodoc": tipodoc, "numdoc": numdoc }

        var bytes = base64.decode("manuel");
        tipodoc = bytes
        console.log("iddocum ",tipodoc)

        this.init(tiponum)
        // this.addDocumento({Iddocum: tipodoc})  

      } else {	
        console.log("no se encotnro documento tiponum")
        // tipodoc = this.getDocumento.Iddocum
      }

    },

    computed: {
      // ...mapGetters('carrito',['getCarrito','getTC']),
      ...mapGetters('documento',['getDocumento']),
      ...mapGetters('Login',['getdatosUsuario','getClientes','getCliente','getModo']),
      // ...mapGetters('direccion',['getDirecciones', 'getDireccion']),
    },

    methods: {
      ...mapActions('documento',['addTotales','addDocumento']),
      ...mapActions('direccion',['traerDirecciones']),

      // COMPROBANTE DE PAGO
      mostrarComprobante(){
        this.$router.push({name:'impresion'}).catch(err => {console.log(err)})
      },

      regresar(){
        this.$router.push({name:'consultadoc'}).catch(err => {console.log(err)})
      },

      init (tiponum) {
        console.log("tiponum", tiponum)
        this.loading = true

        // this.traerDirecciones(this.getdatosUsuario.numcli).then(response =>{
        //    console.log("direcciones carrito", response)
        // })

        this.$http.get('cialist').then(response=>{
          console.log("EMPRESA", response)
          this.getCIA = response.data.result
          // commit('EMPRESA', respuesta.body)
          }, error => {
          console.log(error)
        })


        this.$http.post('v2/entradaskam.get', tiponum).then(response => {
          console.log("response order info", response)

          response.data.result.Docum.importe = accounting.unformat(response.data.result.Docum.importe)
          response.data.result.Docum.impuesto1 = accounting.unformat(response.data.result.Docum.impuesto1)
          response.data.result.Docum.descuento = accounting.unformat(response.data.result.Docum.descuento)

          this.total = accounting.formatNumber( (parseFloat(response.data.result.Docum.importe) - parseFloat(response.data.result.Docum.descuento) + parseFloat(response.data.result.Docum.impuesto1) ),2)

          response.data.result.Docum.importe = accounting.formatNumber(response.data.result.Docum.importe, 2)
          response.data.result.Docum.impuesto1 = accounting.formatNumber(response.data.result.Docum.impuesto1, 2)
          response.data.result.Docum.descuento  = accounting.formatNumber(response.data.result.Docum.descuento, 2)

          this.Docum    = response.data.result.Docum

          if (response.data.result.Clientes != null){
            this.Clientes = response.data.result.Clientes
          }

          this.Movim    = response.data.result.Movim
          this.crearArreglo()

          if (this.Docum.tipodoc == 'EN') {
            this.tipo = 'ENTRADA'
          } else {
            this.tipo = 'SALIDA'
          }


          //FORMAR FOTOS
          let cFotoid = this.Docum.numdoc.trim()
          this.getFotoSello = "https://kam.saiterp.com/imagen/entradas/" + cFotoid+ 'fotoSello.png'
          this.getFotoCajaCerrada = "https://kam.saiterp.com/imagen/entradas/" + cFotoid+ 'fotoCajaCerrada.png'

          this.getFotoCajaVacia = "https://kam.saiterp.com/imagen/entradas/" + cFotoid+ 'fotoCajaVacia.png'
          this.getFotoAvance2 = "https://kam.saiterp.com/imagen/entradas/" + cFotoid+ 'fotoAvance2.png'
          this.getFotoAvance3 = "https://kam.saiterp.com/imagen/entradas/" + cFotoid+ 'fotoAvance3.png'
          this.getFotoCajaAbierta = "https://kam.saiterp.com/imagen/entradas/" + cFotoid+ 'fotoCajaAbierta.png'
  



          this.cards = [
            { nombre: "Foto Sello", src: this.getFotoSello },
            { nombre: "Foto Caja Cerrada", src: this.getFotoCajaCerrada },

            { nombre: "Foto Caja Abierta", src: this.getFotoCajaAbierta },
            { nombre: "Foto Avance Mitad", src: this.getFotoAvance2 },
            { nombre: "Foto Avance 3/4",   src: this.getFotoAvance3 },
            
            { nombre: "Foto Caja Vacia",   src: this.getFotoCajaVacia },
                          
          ]

          // console.log(this.getFotoSello)
          // if (this.Docum.Direnvio.trim() != '' && this.Docum.Numclisuc !=''){
          //   console.log("getnumclisuc", this.Docum.numclisuc)
          //   this.radios='2'
          //   this.Docum.numclisuc 
          //   var cNumclisuc = this.Docum.numclisuc.trim()

          //   this.$http.get('auth/api/v1/getnumclisuc/'+ cNumclisuc).then(response=>{
          //     console.log('numclisuc estado', response)
          //     this.idweb= response.data.idweb
          //     this.select = response.data  	 
          //   })

          // } else {
          //   this.radios="1"
          // }

        }).catch(function(error){
          console.log(error)
        }).finally(()  => this.loading = false) 
      },

      crearArreglo(){
        this.Movim.forEach(element=> {
          element.precio   = accounting.unformat(element.precio)
          element.impuesto1= accounting.unformat(element.impuesto1)
          element.impuesto2= accounting.unformat(element.impuesto2)
          element.importe  = accounting.formatNumber(element.cant * accounting.unformat(element.precio),2)
          element.iva  = 0
          element.total= 0
        })

        this.MovinArray = this.Movim
        this.longitud= this.MovinArray.length

          // console.log("crearArreglo")
        this.formarMovim(this.Movim)
      },

        //MANDAR IMPRIMIR
      order(){
        var totales = {
        importe: this.Importe,
        descuento:  this.Descuento, 
        subtotal: this.Subtotal,
        iva: this.Iva,
        total: this.Total
        }

        //Vuex
        this.addTotales(totales)
        this.$router.push({name: 'tipopago', params:{ tipo: "1"} }).catch(err => {console.log(err)})
      }
  }
};
</script>
