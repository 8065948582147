// this.getMovim-js 

// Este mix in va a contener un solo método. tempg
//Recibe un arreglo y lo regresa con los calculos de la grid de ventas f4. 

var accounting = require("accounting");

export default {

    data () {
      return {
        tempg:[],
        importedoc:0.00,
        descuentodoc:0.00,
        total: 0.00,
        iva:0.00,
        subtotal:0.00,
     }   
   },  

  
	methods:{

		formarMovim(tempg){
      // console.log("formarMovim")
      //1. Vamos a manejar igual que en Sait Tempg para grid de la venta.
      // Se manda llamar desde el Método al mixins.

        //2. Unformar para poder usar los numericos.
        for (var j = tempg.length - 1; j >= 0; j--) {
          tempg[j].importe = accounting.unformat(tempg[j].Precio)
          tempg[j].Precio = accounting.unformat(tempg[j].Precio)
        }

        // OBJETO DOCUM 
        this.total = 0
        this.iva = 0
        this.subtotal = 0
        this.descuentodoc = 0
        this.importedoc = 0
        // console.log("Carrito", tempg)

        //hacer el subtotal, iva y total
        for (var i = tempg.length - 1; i >= 0; i--) {

          // console.log("Precio", tempg[i].Precio)
          // console.log("cant", tempg[i].Cant)
          // console.log("Pjedesc", tempg[i].Pjedesc)
          // console.log("Impuesto1", tempg[i].Impuesto1)
          
          // Importe = Movim.Cant*Movim.Precio
          var importepar = tempg[i].Cant * tempg[i].Precio
          // console.log("Importe ", importepar)

          //descuento = Sum(Movim.Cant*Movim.Precio *Movim.Pjedesc *.01 
          var descuento = tempg[i].Cant * tempg[i].Precio * tempg[i].Pjedesc * 0.01
          // console.log("Descuento ", descuento)

          //SubTotal  = Sum(Movim.Cant*Movim.Precio*(1-Movim.Pjedesc *.01)
          var subtotalpar=tempg[i].Cant *tempg[i].Precio *(1-tempg[i].Pjedesc*0.01)
          // console.log("subtotalpar", subtotalpar)

          //IVA = Sum(Movim.Cant*Movim.Precio*(Movim.Impuesto1 *.01)*(1-Movim.Pjedesc*.01)) As IVA,
          var ivapar = tempg[i].Cant * tempg[i].Precio * (tempg[i].Impuesto1*0.01) *(1-tempg[i].Pjedesc*0.01)
          // console.log("iva par", ivapar)

          // Total = Sum(Movim.Cant*Movim.Precio*(1+Movim.Impuesto1 *.01)*(1-Movim.Pjedesc*.01) 
          var totalpar = tempg[i].Cant * tempg[i].Precio *(1+ tempg[i].Impuesto1*0.01)* (1-tempg[i].Pjedesc*0.01)  
          // console.log("totalpar",totalpar)        

          //Actualizar vuex
          tempg[i].Importe   = importepar
          tempg[i].Descuento = descuento
          tempg[i].Subtotal  = subtotalpar
          tempg[i].Iva       = ivapar
          tempg[i].Total     = totalpar

          // Calcular Totales 
          this.Importe      = this.Importe   + importepar
          this.Descuento    = this.Descuento + descuento
          this.Subtotal     = this.Subtotal  + subtotalpar
          this.Iva          = this.Iva       + ivapar
          this.Total        = this.Total     + totalpar


          //Dar Formato.
          tempg[i].Total     = accounting.formatNumber(tempg[i].Total, 2)
          tempg[i].Descuento = accounting.formatNumber(tempg[i].Descuento, 2)
          tempg[i].Subtotal  = accounting.formatNumber(tempg[i].Subtotal, 2)
          tempg[i].Iva       = accounting.formatNumber(tempg[i].Iva, 2)
          tempg[i].Importe   = accounting.formatNumber(tempg[i].Importe, 2)
          tempg[i].Precio    = accounting.formatNumber(tempg[i].Precio, 2)
          
        }

        //DAR FORMATO       
        this.Total       = accounting.formatNumber(this.Total, 2)
        this.Iva         = accounting.formatNumber(this.Iva, 2)
        this.Subtotal    = accounting.formatNumber(this.Subtotal, 2)
        this.Descuento   = accounting.formatNumber(this.Descuento, 2)
        this.Importe     = accounting.formatNumber(this.Importe, 2)

    }
	}	
}
